import * as S from './search-refinement-form.styled'
import { Icon, Icons } from 'src/components/primitives/icon/icon'
import { Button } from 'src/components/primitives/button'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SearchRefinementEditor } from '../search-refinement-editor'
import type { Editor } from '@tiptap/react'
import { Caption } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives'
import { CriteriaKey, DEFAULT_CUSTOM_REQUIREMENT_CRITERIA } from 'src/libs/api/backend/candidate_search'
import type { Criteria, CriteriaValue } from 'src/libs/api/backend/candidate_search'
import { useGenerateSearchCriteria } from 'src/hooks/mutations/use-generate-search-criteria'
import { RefinementFilterCriteria } from '../refinement-filter-criteria'
import { isEmpty, isNil } from 'lodash'
import { useOnClickOutside } from 'usehooks-ts'
import { Tooltip } from 'src/components/primitives/tooltip'
import { addCriteriaToOrderedGroup, diffUpdatedCriterias, getCriteriaGroupOrder, isCriteriaValid, sanitizeRefinementCriteria } from 'src/utils/refinement-criteria'
import type { CriteriaOrderedGroup } from 'src/utils/refinement-criteria'
import { useAtom } from 'jotai'
import { unstructuredCustomRequirementsAtom, updatedCriteriasAtom } from 'src/stores/job-refinement'
import { trackEvent } from 'src/libs/track'
import { TrackingEventType } from 'src/types/track'
import type { Job, JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { useGlobalError } from 'src/hooks/use-global-error'
import { UserRole } from 'src/libs/api/backend/users'
import { useSession } from 'src/hooks/use-session'
import { useCreateStructuredCriteria } from 'src/hooks/mutations/use-create-structured-criteria'
import { viewerRoleNotice } from 'src/libs/user-role-notice'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { CriteriaProperties, NewCriteriaList } from '../refinement-filter-criteria/constants'

export type FilterType = 'searchRefinement' | 'applicantCandidates'

interface SearchRefinementSuggestion {
  tag: string
  query: string
}

interface SearchRefinementFormProps {
  initialCriteria: Criteria | undefined
  isRecommending: boolean
  onSubmit: (criteria: Criteria) => void
  suggestions?: SearchRefinementSuggestion[]
  isNewRefinement?: boolean
  setIsDirty: (isDirty: boolean) => void
  isDirty: boolean
  triggerClose: () => void
  isErrored: boolean
  filterType: FilterType
  job: Job
  searchRefinement?: JobSearchRefinement
  $maxHeight: string
}

export const SearchRefinementForm = ({
  initialCriteria,
  onSubmit,
  isRecommending,
  // suggestions = DEFAULT_REFINEMENT_SUGGESTIONS,
  isNewRefinement = false,
  setIsDirty,
  isDirty,
  triggerClose,
  isErrored = false,
  filterType,
  job,
  searchRefinement,
  $maxHeight
}: SearchRefinementFormProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(isNewRefinement)
  const [firstCharacterInput, setFirstCharacterInput] = useState(false)
  const [searchQuery, setSeachQuery] = useState<string | null>(null)
  const [editor, setEditor] = useState<Editor | null>(null)
  const [generatingCriteria, setGeneratingCriteria] = useState(false)
  const [criteria, setCriteria] = useState<Criteria | undefined>(undefined)
  const [criteriaOrder, setCriteriaOrder] = useState<CriteriaOrderedGroup[]>([])
  const [structuringCriteria, setStructuringCriteria] = useState(false)
  const { generateSearchCriteria } = useGenerateSearchCriteria()
  const { createStructuredCriteria } = useCreateStructuredCriteria()
  const [updatedCriterias, setUpdatedCriterias] = useAtom(updatedCriteriasAtom)
  const inputFieldRef = useRef<HTMLDivElement>(null)
  const { userRole, userHasViewerRole } = useSession()
  const { isGlobalErrorOpen } = useGlobalError()
  const [unstructuredCustomRequirements, setUnstructuredCustomRequirements] = useAtom(unstructuredCustomRequirementsAtom)
  const [isClearing, setIsClearing] = useState(false)
  const [newlyAddedCriteria, setNewlyAddedCriteria] = useState<CriteriaKey | undefined>(undefined)

  const handleUpdate = useCallback((criteriaKey: CriteriaKey, criteriaValue: CriteriaValue): void => {
    if (!isNil(criteriaValue)) {
      setIsDirty(true)
      setNewlyAddedCriteria(criteriaKey)
      setCriteria({ ...(criteria ?? {}), [criteriaKey]: criteriaValue })
    }
  }, [criteria, setIsDirty])

  const updateSystemCriteria = useCallback((criteria: Criteria) => {
    setCriteria(criteria)
    const groupOrder = getCriteriaGroupOrder(criteria)
    setCriteriaOrder(groupOrder)
  }, [])

  useOnClickOutside(
    inputFieldRef,
    () => {
      if (!generatingCriteria) {
        setIsFocused(false)
      }
    }
  )

  useEffect(() => {
    if (firstCharacterInput) {
      setIsFocused(true)
    }
    return () => {
      setFirstCharacterInput(false)
    }
  }, [firstCharacterInput])

  useEffect(() => {
    if (isNil(criteria) && !isNil(initialCriteria)) {
      updateSystemCriteria(initialCriteria)
    }
  }, [criteria, initialCriteria, updateSystemCriteria])

  const updateCriteria = useCallback((newCriteria: Criteria): void => {
    setCriteria(newCriteria)
    setIsDirty(true)
  }, [setCriteria, setIsDirty])

  const isProcessing = useMemo(() => {
    return isRecommending || generatingCriteria
  }, [isRecommending, generatingCriteria])

  const isValid = useMemo(() => {
    return isCriteriaValid(criteria)
  }, [criteria])

  const disableSubmit = useMemo(() => {
    return (isProcessing || !isDirty || !isValid) && !isErrored
  }, [isProcessing, isDirty, isValid, isErrored])

  const clearCriteria = useCallback(() => {
    updateSystemCriteria({
      [CriteriaKey.CURRENT_JOB_TITLES]: [
        {
          name: job.title,
          optional: false,
          negative: false
        }
      ]
    })
  }, [updateSystemCriteria, job])

  const handleSubmit = useCallback(async (searchQuery: string | null): Promise<void> => {
    if (searchQuery && !isProcessing) {
      const trimmedQuery = searchQuery.trim()
      if (trimmedQuery) {
        trackEvent(TrackingEventType.TELL_PIN_WHAT_YOU_ARE_LOOKING_FOR, { query: trimmedQuery })
        setIsDirty(true)
        setGeneratingCriteria(true)
        generateSearchCriteria({
          jobId: job.id,
          instructions: trimmedQuery,
          previousCriteria: sanitizeRefinementCriteria(criteria),
          onSuccess: (data) => {
            const newCriteria = sanitizeRefinementCriteria(data)
            const diff = diffUpdatedCriterias(newCriteria, criteria)
            setUpdatedCriterias(diff)
            updateSystemCriteria(newCriteria)
            setGeneratingCriteria(false)
            setSeachQuery(null)
            editor?.commands.clearContent()
            editor?.commands.blur()
            setIsFocused(false)
          }
        })
      }
    }
  }, [
    criteria,
    editor?.commands,
    generateSearchCriteria,
    isProcessing,
    job.id,
    setIsDirty,
    setUpdatedCriterias,
    updateSystemCriteria
  ])

  const updatedUnstructuredCustomRequirements = useMemo(() => {
    const customRequirements = criteria?.custom_requirements
    if (isNil(customRequirements)) {
      return []
    }
    const updatedCustomRequirements = Array.from(unstructuredCustomRequirements).map((index) => customRequirements[index]).filter((customRequirement) => !!customRequirement?.requirement)
    return updatedCustomRequirements
  }, [unstructuredCustomRequirements, criteria])

  const triggerSourceCandidates = useCallback((sanitizedCriteria: Criteria) => {
    onSubmit(sanitizedCriteria)
    setUpdatedCriterias([])
    editor?.commands.clearContent()
    editor?.commands.blur()
    setIsDirty(false)
    setIsFocused(false)
  }, [onSubmit, setUpdatedCriterias, editor?.commands, setIsDirty])

  const startSourcingCandidates = useCallback(() => {
    if (!disableSubmit) {
      const sanitizedCriteria = sanitizeRefinementCriteria(criteria)
      if (updatedUnstructuredCustomRequirements.length > 0) {
        setStructuringCriteria(true)
        createStructuredCriteria({
          jobId: job.id,
          customRequirements: updatedUnstructuredCustomRequirements,
          criteria: sanitizedCriteria,
          onSuccess: ({ convertedCriteria, isConverted }) => {
            if (isConverted) {
              const newCriteria = sanitizeRefinementCriteria(convertedCriteria)
              const diff = diffUpdatedCriterias(newCriteria, criteria)
              setUpdatedCriterias(diff)
              updateSystemCriteria(newCriteria)
              setUnstructuredCustomRequirements(new Set([]))
            } else {
              triggerSourceCandidates(sanitizedCriteria)
            }
            setStructuringCriteria(false)
          },
          onError: () => {
            setStructuringCriteria(false)
          }
        })
      } else if (!isEmpty(sanitizedCriteria)) {
        triggerSourceCandidates(sanitizedCriteria)
      }
    }
  }, [createStructuredCriteria, criteria, disableSubmit, job.id, setUnstructuredCustomRequirements, setUpdatedCriterias, updateSystemCriteria, updatedUnstructuredCustomRequirements, triggerSourceCandidates])

  // const handleAddSuggestion = useCallback((suggestion: string): void => {
  //   if (editor) {
  //     const metaCharacter = /\s/g
  //     const { $to } = editor.view.state.selection
  //     const lastCharacter = ($to.nodeBefore?.textContent ?? '').slice(-1)
  //     const isLastCharacterASpace = lastCharacter.match(metaCharacter)
  //     if (!lastCharacter || isLastCharacterASpace) {
  //       editor.chain().focus().insertContent(`${suggestion} `).run()
  //     } else {
  //       editor.chain().focus().insertContent(` ${suggestion} `).run()
  //     }
  //   }
  // }, [editor])

  const disabledSubmitTooltipText = useMemo(() => {
    if (userRole === UserRole.VIEWER) {
      return viewerRoleNotice('source candidates')
    }
    if (isProcessing) {
      return 'Pin is recommending candidates'
    }
    if (generatingCriteria) {
      return 'Pin is generating criteria from your instructions'
    }
    if (!isValid) {
      return 'Need 2 or more valid criteria for better results'
    }
    if (!isDirty) {
      return 'No new changes detected'
    }
    return ''
  }, [isProcessing, generatingCriteria, isDirty, isValid, userRole])

  const makeCriteriaItem = useCallback((criteriaKey: CriteriaKey, icon: string | undefined): MenuItemProps | null => {
    const criteriaProperties = CriteriaProperties.get(criteriaKey)
    if (isNil(criteriaProperties)) {
      return null
    }
    const isDisabled = criteriaKey in (criteria ?? {})
    const { label, defaultValue } = criteriaProperties
    return {
      id: criteriaKey,
      title: label,
      icon,
      isDisabled,
      type: 'item',
      itemTooltip: isDisabled
        ? {
            text: 'This criteria is already set',
            position: 'right'
          }
        : criteriaProperties.tooltip,
      onSelect: () => {
        handleUpdate(criteriaKey, defaultValue)
        setCriteriaOrder(addCriteriaToOrderedGroup(criteriaOrder, criteriaKey))
      }
    }
  }, [criteria, criteriaOrder, handleUpdate, setCriteriaOrder])

  const addCriteriaItems = useMemo((): MenuItemProps[] => {
    const items: MenuItemProps[] = []

    items.push(
      {
        id: 'custom-ai-question',
        type: 'item',
        title: 'AI Question',
        margin: {
          top: 6
        },
        trailingTooltip: {
          text: 'Pin can interpret resumes and answer advanced questions about candidates',
          position: 'top'
        },
        trailingIcon: <Icon name={Icons.sparklesSm} />,
        onSelect: () => {
          if (criteria && CriteriaKey.CUSTOM_REQUIREMENTS in criteria) {
            handleUpdate(
              CriteriaKey.CUSTOM_REQUIREMENTS,
              [...(criteria[CriteriaKey.CUSTOM_REQUIREMENTS] ?? []), DEFAULT_CUSTOM_REQUIREMENT_CRITERIA]
            )
          } else {
            handleUpdate(
              CriteriaKey.CUSTOM_REQUIREMENTS,
              [DEFAULT_CUSTOM_REQUIREMENT_CRITERIA]
            )
            setCriteriaOrder(addCriteriaToOrderedGroup(criteriaOrder, CriteriaKey.CUSTOM_REQUIREMENTS))
          }
          trackEvent(TrackingEventType.ADD_CUSTOM_AI_QUESTION)
        }
      }
    )

    NewCriteriaList.forEach((newCriteria) => {
      const { groupTitle, items: groupItems = [] } = newCriteria
      const subItems: MenuItemProps[] = []
      items.push({
        id: `${groupTitle}-separator`,
        title: `--- ${groupTitle} ---`,
        type: 'separator'
      })
      items.push({
        id: groupTitle,
        title: groupTitle,
        type: 'label'
      })
      groupItems.forEach(({ criteriaKey }) => {
        // Custom AI Question is handled separately
        if (criteriaKey !== CriteriaKey.CUSTOM_REQUIREMENTS) {
          const criteriaItem = makeCriteriaItem(criteriaKey as CriteriaKey, undefined)
          if (criteriaItem) {
            subItems.push(criteriaItem)
          }
        }
      })
      items.push(...subItems)
    })
    return items
  }, [criteria, criteriaOrder, handleUpdate, makeCriteriaItem, setCriteriaOrder])

  return (
    <S.Wrapper $isGlobalErrorOpen={isGlobalErrorOpen} $maxHeight={$maxHeight}>
      <S.InputFormWrapper
        ref={inputFieldRef}
        animate={{ height: isFocused ? 256 : 168 }}
        transition={{ duration: 0.2, delay: 0, ease: 'circOut' }}
        $isLoading={isProcessing}
      >
        <Caption size='SM'>
          Search Criteria
        </Caption>
        <S.SearchRefinementForm $isLoading={isProcessing}>
          <S.Container
            onClick={() => {
              if (!isProcessing && !isFocused) {
                setIsFocused(true)
              }
            }}
          >
            <S.Icon $isLoading={isProcessing}>
              <Icon name={Icons.refinementInput} />
            </S.Icon>
            <SearchRefinementEditor
              placeholder='Ask Pin to add or change any criteria for you'
              isEditable={!isProcessing}
              onDataChanged={(data) => {
                if (!isProcessing) {
                  setSeachQuery(data)
                  if (!firstCharacterInput && data.length > 0) {
                    setFirstCharacterInput(true)
                  }
                }
              }}
              forceEditorFocus={isFocused}
              onSubmit={(newQuery: string) => {
                void handleSubmit(newQuery)
              }}
              setEditor={setEditor}
            />
          </S.Container>
          {/* {suggestions && suggestions.length > 0 && newRefinement && (
            <S.SuggestionsArea>
              <S.SuggestedContainer>
                <Caption size='2XS' $color='fgSecondary' $align='left'>SUGGESTED</Caption>
              </S.SuggestedContainer>
              <S.Suggestions style={{ pointerEvents: isSearching ? 'none' : 'all' }}>
                {suggestions.map((suggestion) => (
                  <S.Suggestion
                    key={suggestion.tag}
                    aria-label={`Add ${suggestion.tag} to search input for refinement`}
                    onClick={() => {
                      handleAddSuggestion(suggestion.query)
                    }}
                  >
                    + {suggestion.tag}
                  </S.Suggestion>
                ))}
              </S.Suggestions>
            </S.SuggestionsArea>
          )} */}
        </S.SearchRefinementForm>
        {searchQuery && searchQuery.length > 0
          ? <S.ActionsBar>
              <Button
                $variant="fill"
                $colorTheme="tint"
                $height={32}
                $fontSize={14}
                disabled={!searchQuery}
                loading={isProcessing}
                onClick={() => {
                  void handleSubmit(searchQuery)
                }}
              >
                Modify Search Criteria
              </Button>
              {!isProcessing &&
                <Button
                  $variant='outline'
                  $height={32}
                  $fontSize={14}
                  onClick={() => {
                    editor?.commands.clearContent()
                    editor?.commands.blur()
                    setIsFocused(false)
                    setSeachQuery(null)
                  }}
                >
                  Cancel
                </Button>
              }
            </S.ActionsBar>
          : <S.ActionsBar>
              <Dropdown
                $minWidth='200px'
                $maxWidth='400px'
                $maxHeight="800px"
                disabled={isProcessing}
                filter={{
                  allowFilter: true,
                  filterItemsPlaceholder: 'Filter',
                  variant: 'outline'
                }}
                trigger={
                  <Button
                    nested
                    $variant='raised-light'
                    $height={24}
                    $fontSize={12}
                    trailingIcon={Icons.chevronDownSmall}
                    disabled={isProcessing}
                    onClick={() => {
                      setIsFocused(false)
                    }}
                  >
                    Add New Criteria
                  </Button>
                }
                items={addCriteriaItems}
                onCloseAutoFocus={(event: Event) => {
                  event.preventDefault()
                  event.stopPropagation()
                }}
              />
            </S.ActionsBar>
        }
      </S.InputFormWrapper>
      <RefinementFilterCriteria
        searchRefinement={searchRefinement}
        criteria={criteria}
        setCriteria={updateCriteria}
        updatedGeneratedCriteria={updatedCriterias}
        criteriaOrder={criteriaOrder}
        setCriteriaOrder={setCriteriaOrder}
        disabled={isProcessing}
        newlyAddedCriteria={newlyAddedCriteria}
        setNewlyAddedCriteria={setNewlyAddedCriteria}
        handleUpdate={handleUpdate}
      />
      <S.SourceCandidates>
        <Flex $align='center' $gap={12} $width='auto'>
          <Tooltip
            trigger={
              <span>
                <Button
                  nested
                  $variant='fill'
                  $colorTheme='tint'
                  $height={40}
                  $fontSize={14}
                  disabled={disableSubmit || userHasViewerRole}
                  onClick={startSourcingCandidates}
                  loading={structuringCriteria}
                >
                  {filterType === 'applicantCandidates'
                    ? 'Filter Applicants'
                    : 'Source Candidates'
                  }
                </Button>
              </span>
            }
            disabled={!disableSubmit}
            triggerDisabled={disableSubmit}
          >
            {disabledSubmitTooltipText}
          </Tooltip>
          {!isNewRefinement && (
            <Button
              $variant='raised-light'
              $height={40}
              $fontSize={14}
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                triggerClose()
              }}
            >
              Cancel
            </Button>
          )}
        </Flex>
        {isClearing
          ? <Flex $align='center' $gap={16} $width='fit-content'>
              <Caption size='SM' $fontWeight={400}>Clear all criterias?</Caption>
              <Flex $align='center' $gap={8} $width='auto'>
                <Button
                  $variant='fill'
                  $colorTheme='negative'
                  $height={24}
                  $fontSize={12}
                  onClick={() => {
                    clearCriteria()
                    setIsClearing(false)
                  }}
                >
                  Clear
                </Button>
                <Button
                  $variant='outline'
                  $height={24}
                  $fontSize={12}
                  onClick={() => {
                    setIsClearing(false)
                  }}
                >
                  Cancel
                </Button>
              </Flex>
            </Flex>
          : <Button
              $variant='ghost'
              $colorTheme='muted'
              $height={24}
              $fontSize={12}
              trailingIcon={Icons.trash}
              disabled={isEmpty(criteria) || isProcessing}
              onClick={() => {
                setIsClearing(true)
              }}
            >
              Clear all
            </Button>
        }
      </S.SourceCandidates>
    </S.Wrapper>
  )
}
