import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { searchEntityGroups } from 'src/libs/api/backend/typeaheads'
import type { EntityGroupSuggestion, EntityGroupType } from 'src/libs/api/backend/typeaheads'
import { queryKeys } from 'src/libs/query-keys'

export const useSearchEntityGroups = ({
  query,
  entityGroup,
  enabled
}: {
  query: string | undefined
  entityGroup: EntityGroupType
  enabled: boolean
}): UseQueryResult<EntityGroupSuggestion[]> => {
  return useQuery({
    queryKey: [queryKeys.entityGroupsSuggestions, query, entityGroup],
    queryFn: async () => await searchEntityGroups({ query, entityGroup }),
    staleTime: 60 * 1000,
    enabled
  })
}
