import * as S from './index.styled'
import { SearchEntityType, TimeScope, CriteriaKey } from 'src/libs/api/backend/candidate_search'
import type { CriteriaValue, SearchEntityCriteria } from 'src/libs/api/backend/candidate_search'
import { CriteriaPill } from './criteria-pill'
import type { CriteriaPillValue, PillInputValue } from './criteria-pill'
import { RemoveCriteria } from './remove-criteria'
import { useCallback, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { SuggestionInput } from './suggestion-input'
import type { Suggestion } from './suggestion-input'
import type { CompanySuggestion } from 'src/libs/api/backend/company_preferences'
import { useCompanySuggestion } from 'src/hooks/queries/use-company-suggestion'
import { useSearchEntityGroups } from 'src/hooks/queries/use-search-entity-groups'
import { EntityGroupType } from 'src/libs/api/backend/typeaheads'

interface SearchEntityRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: SearchEntityCriteria[]
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey) => void
  disabled: boolean
}

export const SearchEntityRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  disabled
}: SearchEntityRowProps): JSX.Element => {
  const [query, setQuery] = useState<string | undefined>(undefined)
  const setQueryDebounced = useMemo(() => {
    return debounce(setQuery, 300)
  }, [])

  const entityGroup = useMemo(() => {
    switch (criteriaKey) {
      case CriteriaKey.CURRENT_JOB_TITLES:
      case CriteriaKey.PREVIOUS_JOB_TITLES:
        return EntityGroupType.JOB_TITLE
      case CriteriaKey.SCHOOLS_ATTENDED:
        return EntityGroupType.SCHOOL
      case CriteriaKey.INVESTORS:
        return EntityGroupType.INVESTOR
      case CriteriaKey.CERTIFICATES:
        return EntityGroupType.CERTIFICATION
      case CriteriaKey.COMPANY_FILTER:
      default:
        return EntityGroupType.COMPANY
    }
  }, [criteriaKey])

  const [entityIcons, setEntityIcons] = useState<Record<string, string>>({})
  const { isPending: isCompanySuggestionsLoading, data: companySuggestions = [] } = useCompanySuggestion({ query, enabled: !!query && criteriaKey === CriteriaKey.COMPANY_FILTER })
  const { isPending: isEntityGroupsSuggestionsLoading, data: entityGroupsSuggestions = [] } = useSearchEntityGroups({
    query,
    entityGroup,
    enabled: !!entityGroup
  })

  const isLoading = useMemo(() => {
    return !!query &&
      (
        isEntityGroupsSuggestionsLoading ||
        (criteriaKey === CriteriaKey.COMPANY_FILTER && isCompanySuggestionsLoading)
      )
  }, [criteriaKey, isCompanySuggestionsLoading, isEntityGroupsSuggestionsLoading, query])

  const suggestions = useMemo(() => {
    let suggestions: Suggestion[] = []
    if (criteriaKey === CriteriaKey.COMPANY_FILTER) {
      suggestions = suggestions.concat(companySuggestions)
    }
    suggestions = suggestions.concat(entityGroupsSuggestions)
    return suggestions
  }, [criteriaKey, companySuggestions, entityGroupsSuggestions])

  const items = useMemo(() => {
    if (criteriaValue.length === 0) {
      return undefined
    }
    return criteriaValue.map((entityValue, index) => {
      const subSubTitle: string[] = []
      if (entityValue.min_duration_years) {
        subSubTitle.push(`${entityValue.min_duration_years}yr+`)
      }
      if (entityValue.duration_year_min ?? entityValue.duration_year_max) {
        subSubTitle.push(`(${entityValue.duration_year_min ?? 'Any'} - ${entityValue.duration_year_max ?? 'Any'})`)
      }
      let timeScope: string | undefined
      if (entityValue.time_scope === TimeScope.CURRENT) {
        timeScope = 'Current' + (subSubTitle.length > 0 ? ',' : '')
      } else if (entityValue.time_scope === TimeScope.PREVIOUS) {
        timeScope = 'Past' + (subSubTitle.length > 0 ? ',' : '')
      }
      const pillValue: PillInputValue = {
        label: entityValue.name ?? '',
        icon: entityValue.logoUrl
          ? entityValue.logoUrl
          : entityValue.name
            ? entityIcons[entityValue.name]
            : undefined,
        subtitle: [timeScope, ...subSubTitle].join(' '),
        timeScope: entityValue.time_scope,
        optional: entityValue.optional ?? true,
        negative: entityValue.negative ?? false
      }

      if (criteriaKey === CriteriaKey.COMPANY_FILTER) {
        pillValue.durationYearMin = entityValue.duration_year_min
        pillValue.durationYearMax = entityValue.duration_year_max
        pillValue.minDurationYears = entityValue.min_duration_years
      }

      return (
        <CriteriaPill
          key={index}
          value={pillValue}
          onUpdate={(key: string, value: CriteriaPillValue) => {
            const updatedCriteriaValue = [...criteriaValue]
            const updatedValue: SearchEntityCriteria = {
              ...updatedCriteriaValue[index],
              [key]: value
            }
            if (key === 'time_scope' &&
              value === TimeScope.CURRENT &&
              'duration_year_min' in updatedValue &&
              'duration_year_max' in updatedValue
            ) {
              updatedValue.duration_year_min = null
              updatedValue.duration_year_max = null
            }
            updatedCriteriaValue[index] = updatedValue
            onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
          }}
          onRemove={() => {
            const updatedCriteriaValue = criteriaValue.filter((_, i) => i !== index)
            onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
          }}
        />
      )
    })
  }, [criteriaKey, criteriaValue, entityIcons, onCriteriaUpdate])

  const handleAddNew = useCallback((suggestion: Suggestion) => {
    const newCriteriaValue: SearchEntityCriteria = {
      name: suggestion.name,
      linkedin: (suggestion as CompanySuggestion).linkedin ?? undefined,
      domain: (suggestion as CompanySuggestion).website ?? undefined,
      logoUrl: (suggestion as CompanySuggestion).logoUrl ?? undefined,
      time_scope: TimeScope.ANY,
      type: 'groupType' in suggestion ? SearchEntityType.GROUP : SearchEntityType.INDIVIDUAL,
      optional: false,
      negative: false
    }
    if (criteriaKey === CriteriaKey.COMPANY_FILTER) {
      newCriteriaValue.duration_year_min = null
      newCriteriaValue.duration_year_max = null
      newCriteriaValue.min_duration_years = 1
    }

    onCriteriaUpdate(
      criteriaKey,
      [...criteriaValue, newCriteriaValue]
    )
    if ('logoUrl' in suggestion && suggestion.logoUrl) {
      setEntityIcons({
        ...entityIcons,
        [suggestion.name]: suggestion.logoUrl
      })
    }
  }, [criteriaKey, criteriaValue, entityIcons, onCriteriaUpdate])

  return (
    <S.RowContainer>
      <SuggestionInput
        isLoading={isLoading}
        disabled={disabled}
        items={items}
        triggerSearch={setQueryDebounced}
        suggestions={suggestions}
        onSelect={(suggestion) => {
          handleAddNew(suggestion)
        }}
        // onKeyUp={(input) => {
        //   onCriteriaUpdate(
        //     criteriaKey,
        //     [...criteriaValue, {
        //       name: input,
        //       linkedin: undefined,
        //       domain: undefined,
        //       logoUrl: undefined,
        //       optional: false,
        //       negative: false,
        //       time_scope: TimeScope.ANY,
        //       type: SearchEntityType.INDIVIDUAL
        //     }]
        //   )
        // }}
      />
      <RemoveCriteria
        onRemove={() => {
          onCriteriaRemove(criteriaKey)
        }}
        disabled={disabled}
      />
    </S.RowContainer>
  )
}
