import { CriteriaKey, DEFAULT_BOOLEAN_CRITERIA, DEFAULT_CUSTOM_REQUIREMENT_CRITERIA, DEFAULT_DEGREE_CRITERIA, DEFAULT_MIN_MAX_CRITERIA, EmploymentStatus, Race, Sex, TimeScope } from 'src/libs/api/backend/candidate_search'
import type { CriteriaValue } from 'src/libs/api/backend/candidate_search'

export type CriteriaType =
  'location' |
  'company' |
  'school' |
  'boolean' |
  'degree-major' |
  'job-title' |
  'entity-group' |
  'min-max' |
  'custom-requirement' |
  'standard' |
  'name-optional' |
  'language' |
  'lookalike' |
  'demographics'

export const FUNDING_ROUND_CRITERIA = new Map<number, string>([
  [1, 'Pre-Seed'],
  [2, 'Seed'],
  [3, 'Series A'],
  [4, 'Series B'],
  [5, 'Series C'],
  [6, 'Series D'],
  [7, 'Series E'],
  [8, 'Series F+'],
  [9, 'IPO']
])

export const DEGREE_OPTIONS = new Map<number, string>([
  [1, 'Associate'],
  [2, 'Bachelors'],
  [3, 'Masters'],
  [4, 'Doctorate / PhD']
])

export const TIME_SCOPE_OPTIONS = new Map<TimeScope, string>([
  [TimeScope.CURRENT, 'Current Position'],
  [TimeScope.PREVIOUS, 'Past Positions'],
  [TimeScope.ANY, 'Any Position']
])

export const SENIORITY_OPTIONS = new Map<number, string>([
  [1, 'Unpaid'],
  [2, 'Training'],
  [3, 'Entry'],
  [4, 'Senior'],
  [5, 'Manager'],
  [6, 'Director'],
  [7, 'VP'],
  [8, 'Partner'],
  [9, 'C-level'],
  [10, 'Owner']
])

export const GRADUATION_YEARS = new Map(
  Array.from({ length: new Date().getFullYear() - 1980 + 1 }, (_, index) => {
    const year = new Date().getFullYear() - index
    return [year, year.toString()]
  })
)

export const TIMEZONE_OPTIONS = new Map<string, string>([
  ['alaska_time', 'Alaska Time'],
  ['atlantic_time', 'Atlantic Time'],
  ['central_time', 'Central Time'],
  ['eastern_time', 'Eastern Time'],
  ['hawaii_aleutian_time', 'Hawaii Aleutian Time'],
  ['mountain_time', 'Mountain Time'],
  ['newfoundland_time', 'Newfoundland Time'],
  ['pacific_time', 'Pacific Time'],
  ['western_european_time', 'Western European Time'],
  ['central_european_time', 'Central European Time'],
  ['eastern_european_time', 'Eastern European Time'],
  ['further_eastern_european_time', 'Further Eastern European Time']
])

export const RACE_OPTIONS = new Map<string, string>([
  [Race.ASIAN, 'Asian'],
  [Race.BLACK_OR_AFRICAN_AMERICAN, 'Black or African American'],
  [Race.HISPANIC_OR_LATINO, 'Hispanic or Latinx']
])

export const SEX_OPTIONS = new Map<string, string>([
  [Sex.FEMALE, 'Female'],
  [Sex.MALE, 'Male']
])

export const EMPLOYMENT_STATUS_OPTIONS = new Map<string, string>([
  [EmploymentStatus.EMPLOYED, 'Employed'],
  [EmploymentStatus.UNEMPLOYED, 'Unemployed'],
  [EmploymentStatus.FREELANCER, 'Freelancer'],
  [EmploymentStatus.OWNER, 'Owner/Founder']
])

export const HEADCOUNT_OPTIONS = new Map<number, Record<string, string>>([
  [0, { label: '0 Employees', subLabel: 'Self Employed' }],
  [1, { label: '1-10 Employees', subLabel: 'Micro' }],
  [2, { label: '11-50 Employees', subLabel: 'Small' }],
  [3, { label: '51-200 Employees', subLabel: 'Small-Medium' }],
  [4, { label: '201-500 Employees', subLabel: 'Medium' }],
  [5, { label: '501-1000 Employees', subLabel: 'Medium-Large' }],
  [6, { label: '1001-5000 Employees', subLabel: 'Large' }],
  [7, { label: '5001-10000 Employees', subLabel: 'Large' }],
  [8, { label: '10001+ Employees', subLabel: 'Large Enterprises' }]
])

export const DEFAULT_CRITERIA = {
  [CriteriaKey.CURRENT_JOB_TITLES]: []
}

export const CriteriaProperties = new Map<string, {
  label: string
  defaultValue: CriteriaValue
  options?: Map<number | string, string>
  type: CriteriaType
  tooltip?: {
    text: string
    position?: 'top' | 'bottom' | 'left' | 'right'
    align?: 'start' | 'center' | 'end'
  }
}>([
  [CriteriaKey.CURRENT_JOB_TITLES, {
    label: 'Current Job Titles',
    defaultValue: [],
    type: 'job-title'
  }],
  [CriteriaKey.PREVIOUS_JOB_TITLES, {
    label: 'Previous Job Titles',
    defaultValue: [],
    type: 'job-title'
  }],
  [CriteriaKey.LOCATIONS, {
    label: 'Candidate Location',
    defaultValue: [],
    type: 'location'
  }],
  [CriteriaKey.PREVIOUS_LOCATIONS, {
    label: 'Previous Locations',
    defaultValue: [],
    type: 'location'
  }],
  [CriteriaKey.MONTHS_IN_CURRENT_PROFESSION, {
    label: 'Years in Current Profession',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    tooltip: {
      text: 'Counts only years in the candidate’s current seniority and profession. For total work history across their entire career, use ‘Total Years of Experience’',
      position: 'top',
      align: 'start'
    },
    type: 'min-max'
  }],
  [CriteriaKey.MONTHS_OF_PROFESSIONAL_EXPERIENCE, {
    label: 'Total Years of Experience',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    type: 'min-max'
  }],
  [CriteriaKey.MONTHS_AT_CURRENT_COMPANY, {
    label: 'Years at Current Company',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    type: 'min-max'
  }],
  [CriteriaKey.CURRENT_COMPANY_HC, {
    label: "Company's Current Headcount",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    type: 'min-max'
  }],
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_HEADCOUNT, {
    label: "Company's Headcount at Time of Hire",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    type: 'min-max'
  }],
  [CriteriaKey.CURRENT_COMPANY_CURRENT_FUNDING_ROUND, {
    label: "Company's Current Funding Stage",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: FUNDING_ROUND_CRITERIA,
    type: 'min-max'
  }],
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_FUNDING_ROUND, {
    label: "Company's Funding Stage at Time of Hire",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: FUNDING_ROUND_CRITERIA,
    type: 'min-max'
  }],
  [CriteriaKey.AVERAGE_TENURE_IN_MONTHS, {
    label: 'Average Tenure at Companies',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    type: 'min-max'
  }],
  [CriteriaKey.COMPANIES, {
    label: 'Companies Worked At',
    defaultValue: [],
    type: 'company'
  }],
  [CriteriaKey.CURRENT_COMPANY, {
    label: 'Current Company',
    defaultValue: [],
    type: 'company'
  }],
  [CriteriaKey.ANY_COMPANY_JOINED_AT_HEADCOUNT, {
    label: 'Headcount When Hired at Any Company',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    type: 'min-max'
  }],
  [CriteriaKey.ANY_COMPANY_JOINED_AT_FUNDING_ROUND, {
    label: 'Funding Stage When Hired at Any Company',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: FUNDING_ROUND_CRITERIA,
    type: 'min-max'
  }],
  [CriteriaKey.SCHOOLS, {
    label: 'Universities Attended',
    defaultValue: [],
    type: 'school'
  }],
  [CriteriaKey.ATTENDED_IVY_LEAGUE, {
    label: 'Attended Ivy League',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA,
    type: 'boolean'
  }],
  [CriteriaKey.GRADUATION_YEAR, {
    label: 'Graduation Year',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: GRADUATION_YEARS,
    type: 'min-max'
  }],
  [CriteriaKey.DEGREE, {
    label: 'Highest Degree',
    defaultValue: DEFAULT_DEGREE_CRITERIA,
    options: DEGREE_OPTIONS,
    type: 'min-max'
  }],
  [CriteriaKey.DEGREE_MAJOR_PAIRS, {
    label: 'Major & Field of Study',
    defaultValue: [],
    type: 'degree-major'
  }],
  [CriteriaKey.CURRENT_COMPANY_HAS_NOTABLE_INVESTOR, {
    label: 'Works at a Company with a Notable Investor',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA,
    type: 'boolean'
  }],
  [CriteriaKey.WORKED_AT_COMPANY_WITH_NOTABLE_INVESTOR, {
    label: 'Worked at a Company with a Notable Investor',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA,
    type: 'boolean'
  }],
  [CriteriaKey.WORKS_AT_YC_COMPANY, {
    label: 'Works at a YC-backed Company',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA,
    type: 'boolean'
  }],
  [CriteriaKey.WORKED_AT_YC_COMPANY, {
    label: 'Worked at a YC-backed Company',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA,
    type: 'boolean'
  }],
  [CriteriaKey.CUSTOM_REQUIREMENTS, {
    label: 'AI Custom Criteria',
    defaultValue: [DEFAULT_CUSTOM_REQUIREMENT_CRITERIA],
    type: 'custom-requirement'
  }],
  [CriteriaKey.KNOWN_LANGUAGES, {
    label: 'Languages',
    defaultValue: [],
    type: 'language'
  }],
  [CriteriaKey.ASSIGNED_TIMEZONES, {
    label: 'Timezone',
    defaultValue: [],
    options: TIMEZONE_OPTIONS,
    type: 'standard'
  }],
  [CriteriaKey.SEX, {
    label: 'Gender',
    defaultValue: [],
    options: SEX_OPTIONS,
    type: 'standard'
  }],
  [CriteriaKey.RACE_CATEGORY, {
    label: 'Ethnicities',
    defaultValue: [],
    options: RACE_OPTIONS,
    type: 'standard'
  }],
  [CriteriaKey.VETERAN_STATUS, {
    label: 'Veteran Status',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA,
    type: 'boolean'
  }],
  [CriteriaKey.DISABILITY_STATUS, {
    label: 'Disability Status',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA,
    type: 'boolean'
  }],
  [CriteriaKey.CURRENTLY_EMPLOYED, {
    label: 'Currently Employed',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA,
    type: 'boolean'
  }],
  [CriteriaKey.HAS_VISA_SPONSORSHIP, {
    label: 'Visa Requirements',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA,
    type: 'boolean'
  }],
  [CriteriaKey.EXPERIENCE_IN_FUNDING_ROUNDS, {
    label: 'Experience in Funding Rounds',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: FUNDING_ROUND_CRITERIA,
    type: 'min-max'
  }],
  // V3 Criteria
  [CriteriaKey.KEYWORDS, {
    label: 'Keywords',
    defaultValue: [],
    type: 'job-title'
  }],
  [CriteriaKey.LOOKALIKE_CANDIDATES, {
    label: 'Lookalike Search',
    defaultValue: {
      linkedin: '',
      negative: false,
      optional: false
    },
    type: 'lookalike'
  }],
  [CriteriaKey.SENIORITY, {
    label: 'Seniority',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: SENIORITY_OPTIONS,
    type: 'min-max'
  }],
  [CriteriaKey.SKILLS, {
    label: 'Skill & Tools',
    defaultValue: [],
    type: 'entity-group'
  }],
  [CriteriaKey.EMPLOYEE_OVERLAP, {
    label: 'Employee Overlap',
    defaultValue: {
      department_id: null,
      min: 0,
      max: 0,
      optional: false
    },
    type: 'min-max'
  }],
  [CriteriaKey.COMPANY_FILTER, {
    label: 'Company Name',
    defaultValue: [],
    type: 'entity-group'
  }],
  [CriteriaKey.INDUSTRIES, {
    label: 'Industry',
    defaultValue: [],
    type: 'entity-group'
  }],
  [CriteriaKey.HQ_LOCATIONS, {
    label: 'HQ Location',
    defaultValue: [],
    type: 'location'
  }],
  [CriteriaKey.INVESTORS, {
    label: 'Investors',
    defaultValue: [],
    type: 'entity-group'
  }],
  [CriteriaKey.DEMOGRAPHICS, {
    label: 'Demographics',
    defaultValue: [],
    type: 'demographics'
  }],
  [CriteriaKey.EMPLOYMENT_STATUS, {
    label: 'Employment Status',
    defaultValue: {
      name: EmploymentStatus.EMPLOYED,
      optional: false
    },
    options: EMPLOYMENT_STATUS_OPTIONS,
    type: 'name-optional'
  }],
  [CriteriaKey.SCHOOLS_ATTENDED, {
    label: 'Schools Attended',
    defaultValue: [],
    type: 'entity-group'
  }],
  [CriteriaKey.CERTIFICATES, {
    label: 'Certificates',
    defaultValue: [],
    type: 'entity-group'
  }],
  [CriteriaKey.FUNDING_ROUND, {
    label: 'Funding Round',
    defaultValue: {
      min: undefined,
      max: undefined,
      optional: false,
      time_scope: TimeScope.ANY
    },
    options: FUNDING_ROUND_CRITERIA,
    type: 'min-max'
  }],
  [CriteriaKey.HEADCOUNT, {
    label: 'Headcount',
    defaultValue: {
      min: undefined,
      max: undefined,
      optional: false,
      time_scope: TimeScope.ANY
    },
    type: 'min-max'
  }]
])

export enum CriteriaGroup {
  GENERAL_CRITERIA = 'General Criteria',
  CANDIDATE_EXPERIENCE = 'Candidate Experience',
  WORK_POSITIONS = 'Work Positions',
  CANDIDATE_DETAILS = 'Candidate Details',
  EDUCATION = 'Education'
}

export const CriteriaGroupOrder = [
  CriteriaGroup.GENERAL_CRITERIA,
  CriteriaGroup.CANDIDATE_EXPERIENCE,
  CriteriaGroup.WORK_POSITIONS,
  CriteriaGroup.CANDIDATE_DETAILS,
  CriteriaGroup.EDUCATION
]

export const CriteriaToGroupMap = new Map<CriteriaKey, CriteriaGroup>([
  [CriteriaKey.CURRENT_JOB_TITLES, CriteriaGroup.GENERAL_CRITERIA],
  [CriteriaKey.LOCATIONS, CriteriaGroup.GENERAL_CRITERIA],
  [CriteriaKey.CUSTOM_REQUIREMENTS, CriteriaGroup.GENERAL_CRITERIA],
  [CriteriaKey.KEYWORDS, CriteriaGroup.GENERAL_CRITERIA],
  [CriteriaKey.LOOKALIKE_CANDIDATES, CriteriaGroup.GENERAL_CRITERIA],

  [CriteriaKey.COMPANIES, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.MONTHS_OF_PROFESSIONAL_EXPERIENCE, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.PREVIOUS_JOB_TITLES, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.MONTHS_IN_CURRENT_PROFESSION, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.AVERAGE_TENURE_IN_MONTHS, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.CURRENT_COMPANY_HAS_NOTABLE_INVESTOR, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.WORKED_AT_COMPANY_WITH_NOTABLE_INVESTOR, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.WORKS_AT_YC_COMPANY, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.WORKED_AT_YC_COMPANY, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.EXPERIENCE_IN_FUNDING_ROUNDS, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.ANY_COMPANY_JOINED_AT_HEADCOUNT, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.ANY_COMPANY_JOINED_AT_FUNDING_ROUND, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.SENIORITY, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.SKILLS, CriteriaGroup.CANDIDATE_EXPERIENCE],
  [CriteriaKey.EMPLOYEE_OVERLAP, CriteriaGroup.CANDIDATE_EXPERIENCE],

  [CriteriaKey.COMPANY_FILTER, CriteriaGroup.WORK_POSITIONS],
  [CriteriaKey.INDUSTRIES, CriteriaGroup.WORK_POSITIONS],
  [CriteriaKey.HQ_LOCATIONS, CriteriaGroup.WORK_POSITIONS],
  [CriteriaKey.FUNDING_ROUND, CriteriaGroup.WORK_POSITIONS],
  [CriteriaKey.HEADCOUNT, CriteriaGroup.WORK_POSITIONS],
  [CriteriaKey.INVESTORS, CriteriaGroup.WORK_POSITIONS],
  [CriteriaKey.CURRENT_COMPANY, CriteriaGroup.WORK_POSITIONS],
  [CriteriaKey.MONTHS_AT_CURRENT_COMPANY, CriteriaGroup.WORK_POSITIONS],
  [CriteriaKey.CURRENT_COMPANY_HC, CriteriaGroup.WORK_POSITIONS],
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_HEADCOUNT, CriteriaGroup.WORK_POSITIONS],
  [CriteriaKey.CURRENT_COMPANY_CURRENT_FUNDING_ROUND, CriteriaGroup.WORK_POSITIONS],
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_FUNDING_ROUND, CriteriaGroup.WORK_POSITIONS],

  [CriteriaKey.DEMOGRAPHICS, CriteriaGroup.CANDIDATE_DETAILS],
  [CriteriaKey.EMPLOYMENT_STATUS, CriteriaGroup.CANDIDATE_DETAILS],
  [CriteriaKey.PREVIOUS_LOCATIONS, CriteriaGroup.CANDIDATE_DETAILS],
  [CriteriaKey.ASSIGNED_TIMEZONES, CriteriaGroup.CANDIDATE_DETAILS],
  [CriteriaKey.HAS_VISA_SPONSORSHIP, CriteriaGroup.CANDIDATE_DETAILS],
  [CriteriaKey.KNOWN_LANGUAGES, CriteriaGroup.CANDIDATE_DETAILS],
  [CriteriaKey.CURRENTLY_EMPLOYED, CriteriaGroup.CANDIDATE_DETAILS],

  [CriteriaKey.SCHOOLS_ATTENDED, CriteriaGroup.EDUCATION],
  [CriteriaKey.CERTIFICATES, CriteriaGroup.EDUCATION],
  [CriteriaKey.SCHOOLS, CriteriaGroup.EDUCATION],
  [CriteriaKey.ATTENDED_IVY_LEAGUE, CriteriaGroup.EDUCATION],
  [CriteriaKey.GRADUATION_YEAR, CriteriaGroup.EDUCATION],
  [CriteriaKey.DEGREE, CriteriaGroup.EDUCATION],
  [CriteriaKey.DEGREE_MAJOR_PAIRS, CriteriaGroup.EDUCATION]
])

export const GroupToCriteriaMap = new Map<CriteriaGroup, CriteriaKey[]>([
  [CriteriaGroup.GENERAL_CRITERIA, [
    CriteriaKey.CURRENT_JOB_TITLES,
    CriteriaKey.LOCATIONS,
    // CriteriaKey.KEYWORDS,
    // CriteriaKey.LOOKALIKE_CANDIDATES,
    CriteriaKey.CUSTOM_REQUIREMENTS
  ]],
  [CriteriaGroup.CANDIDATE_EXPERIENCE, [
    CriteriaKey.MONTHS_OF_PROFESSIONAL_EXPERIENCE,
    CriteriaKey.MONTHS_IN_CURRENT_PROFESSION,
    // CriteriaKey.SENIORITY,
    // CriteriaKey.SKILLS,
    CriteriaKey.PREVIOUS_JOB_TITLES,
    // CriteriaKey.COMPANIES,
    CriteriaKey.AVERAGE_TENURE_IN_MONTHS,
    CriteriaKey.CURRENT_COMPANY_HAS_NOTABLE_INVESTOR,
    CriteriaKey.WORKED_AT_COMPANY_WITH_NOTABLE_INVESTOR,
    CriteriaKey.WORKS_AT_YC_COMPANY,
    CriteriaKey.WORKED_AT_YC_COMPANY,
    CriteriaKey.ANY_COMPANY_JOINED_AT_HEADCOUNT,
    CriteriaKey.ANY_COMPANY_JOINED_AT_FUNDING_ROUND,
    CriteriaKey.EXPERIENCE_IN_FUNDING_ROUNDS
    // CriteriaKey.EMPLOYEE_OVERLAP
  ]],
  [CriteriaGroup.WORK_POSITIONS, [
    CriteriaKey.COMPANY_FILTER,
    // CriteriaKey.INDUSTRIES,
    // CriteriaKey.HQ_LOCATIONS,
    // CriteriaKey.FUNDING_ROUND,
    // CriteriaKey.HEADCOUNT,
    // CriteriaKey.INVESTORS,
    // CriteriaKey.CURRENT_COMPANY,
    CriteriaKey.MONTHS_AT_CURRENT_COMPANY,
    CriteriaKey.CURRENT_COMPANY_HC,
    CriteriaKey.CURRENT_COMPANY_JOINED_AT_HEADCOUNT,
    CriteriaKey.CURRENT_COMPANY_CURRENT_FUNDING_ROUND,
    CriteriaKey.CURRENT_COMPANY_JOINED_AT_FUNDING_ROUND
  ]],
  [CriteriaGroup.CANDIDATE_DETAILS, [
    CriteriaKey.PREVIOUS_LOCATIONS,
    CriteriaKey.ASSIGNED_TIMEZONES,
    CriteriaKey.KNOWN_LANGUAGES,
    CriteriaKey.DEMOGRAPHICS,
    CriteriaKey.HAS_VISA_SPONSORSHIP,
    CriteriaKey.CURRENTLY_EMPLOYED
    // CriteriaKey.EMPLOYMENT_STATUS
  ]],
  [CriteriaGroup.EDUCATION, [
    // CriteriaKey.SCHOOLS_ATTENDED,
    CriteriaKey.SCHOOLS,
    CriteriaKey.ATTENDED_IVY_LEAGUE,
    CriteriaKey.GRADUATION_YEAR,
    CriteriaKey.DEGREE,
    CriteriaKey.DEGREE_MAJOR_PAIRS
    // CriteriaKey.CERTIFICATES
  ]]
])

export const DemographicsGroup = new Set([
  CriteriaKey.SEX,
  CriteriaKey.RACE_CATEGORY,
  CriteriaKey.VETERAN_STATUS,
  CriteriaKey.DISABILITY_STATUS
])

export const NewCriteriaList = [
  {
    groupTitle: CriteriaGroup.GENERAL_CRITERIA,
    items: GroupToCriteriaMap.get(CriteriaGroup.GENERAL_CRITERIA)?.map((criteriaKey) => ({
      criteriaKey
    })) ?? []
  },
  {
    groupTitle: CriteriaGroup.CANDIDATE_EXPERIENCE,
    items: GroupToCriteriaMap.get(CriteriaGroup.CANDIDATE_EXPERIENCE)?.map((criteriaKey) => ({
      criteriaKey
    })) ?? []
  },
  {
    groupTitle: CriteriaGroup.WORK_POSITIONS,
    items: GroupToCriteriaMap.get(CriteriaGroup.WORK_POSITIONS)?.map((criteriaKey) => ({
      criteriaKey
    })) ?? []
  },
  {
    groupTitle: CriteriaGroup.CANDIDATE_DETAILS,
    items: GroupToCriteriaMap.get(CriteriaGroup.CANDIDATE_DETAILS)?.map((criteriaKey) => ({
      criteriaKey
    })) ?? []
  },
  {
    groupTitle: CriteriaGroup.EDUCATION,
    items: GroupToCriteriaMap.get(CriteriaGroup.EDUCATION)?.map((criteriaKey) => ({
      criteriaKey
    })) ?? []
  }
]
