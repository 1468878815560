import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form, Input } from 'src/components/forms'
import { Flex } from 'src/components/primitives/flex'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import * as S from './companies-search.styled'
import { Avatar } from 'src/components/primitives/avatar'
import { DivAsButton } from 'src/components/primitives/div-as-button'
import { Caption } from 'src/components/primitives/typography'
import { Icon } from 'src/components/primitives/icon'
import type { CompanySuggestion } from 'src/libs/api/backend/company_preferences'
import { useCompanySuggestion } from 'src/hooks/queries/use-company-suggestion'
import { Button } from 'src/components/primitives/button'
import { When } from '../when'
import { debounce, isNil } from 'lodash'
import { useOnClickOutside } from 'usehooks-ts'
import { Spinner } from 'src/components/primitives/spinner'
import { useSession } from 'src/hooks/use-session'

const companiesSearchSchema = z.object({
  query: z.string().nullish()
})

type CompaniesSearchForm = z.infer<typeof companiesSearchSchema>

interface CompaniesSearchProps {
  onAddCompany?: (company: CompanySuggestion) => void
}

export const CompaniesSearch = ({ onAddCompany }: CompaniesSearchProps): JSX.Element => {
  const { userHasViewerRole } = useSession()
  const [query, setQuery] = useState<string | null>(null)
  const setQueryDebounced = useMemo(() => debounce(setQuery, 300), [])
  const [isSearching, setIsSearching] = useState(false)
  const [focusIndex, setFocusIndex] = useState<number | null>(null)
  const searchInputEl = useRef<HTMLDivElement>(null)
  const resultItemsRef = useRef<Array<HTMLDivElement | null>>([])

  const { submit, register, reset, formData } = useForm<CompaniesSearchForm>({
    schema: companiesSearchSchema
  })

  const handleResetSearch = useCallback(() => {
    setIsSearching(false)
    setQuery(null)
    setFocusIndex(null)
    reset()
  }, [reset])

  useOnClickOutside(searchInputEl, handleResetSearch)

  const handleSubmit = async (formData: CompaniesSearchForm): Promise<void> => {
    if (formData.query) {
      setIsSearching(true)
      setQueryDebounced(formData.query)
    }
  }

  const { isPending, data: companySuggestions } = useCompanySuggestion({ query, enabled: !!query })

  useEffect(() => {
    if (formData?.query) {
      setIsSearching(true)
      setQueryDebounced(formData.query as string)
    }
  }, [formData, setQueryDebounced])

  useEffect(() => {
    if (!isPending && companySuggestions) {
      setIsSearching(false)
    }
  }, [isPending, companySuggestions])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        handleResetSearch()
      } else if (event.key === 'ArrowDown') {
        setFocusIndex((prevIndex) => {
          const nextIndex = prevIndex === null ? 0 : (prevIndex + 1) % (companySuggestions?.length ?? 1)
          resultItemsRef.current[nextIndex]?.focus()
          return nextIndex
        })
      } else if (event.key === 'ArrowUp') {
        setFocusIndex((prevIndex) => {
          const nextIndex = prevIndex === null ? (companySuggestions?.length ?? 0) - 1 : (prevIndex - 1 + (companySuggestions?.length ?? 1)) % (companySuggestions?.length ?? 1)
          resultItemsRef.current[nextIndex]?.focus()
          return nextIndex
        })
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [companySuggestions, handleResetSearch])

  return (
    <S.CompaniesSearch>
      <Form onSubmit={submit(handleSubmit)}>
        <S.SearchInput as="div" ref={searchInputEl} tabIndex={0}>
          <Icon name="search" color="fgSecondary" size={12} />
          <Input
            variant="naked"
            label="Domain"
            hiddenLabel
            name="query"
            placeholder="Search for Company"
            register={register}
            $marginBottom={0}
          />
          <When condition={isSearching}>
            <S.LoadingSpinner>
              <Spinner />
            </S.LoadingSpinner>
          </When>
          <When condition={!isPending && !isNil(companySuggestions) && !isSearching}>
            <Button
              $variant="ghost"
              $height={24}
              $width={48}
              $fontSize={12}
              $colorTheme="muted"
              $align="center"
              onClick={() => {
                setQuery(null)
                setFocusIndex(null)
                reset()
              }}
            >
              Clear
            </Button>
          </When>
          <When condition={(companySuggestions?.length ?? 0) >= 1}>
            <S.Result>
              <S.ResultInner>
                {companySuggestions?.map((s, index) => (
                  <DivAsButton
                    key={s.linkedin}
                    ariaLabel={`Add ${s.name} to list of companies`}
                    onClick={() => {
                      if (onAddCompany) {
                        setIsSearching(false)
                        setQuery(null)
                        setFocusIndex(null)
                        reset()
                        onAddCompany(s)
                      }
                    }}
                    isDisabled={userHasViewerRole}
                    ref={(el) => (resultItemsRef.current[index] = el)}
                  >
                    <S.ResultItem $isFocused={index === focusIndex}>
                      <Flex $gap={8} $align="center">
                        <Avatar
                          $size={24}
                          $type="logo"
                          initials={s.name}
                          company={{
                            name: s.name,
                            url: s.website,
                            logoUrl: s.logoUrl
                          }}
                        />
                        <Caption size="XS" $color="fgPrimary" $whiteSpace="nowrap">
                          {s.name.length >= 54 ? `${s.name.slice(0, 54)}…` : s.name}
                        </Caption>
                      </Flex>
                      <S.ItemCta data-name="result-item-cta">
                        <Icon name="plus" color="tintBg" size={12} />
                        <Caption size="XS" $color="tintBg" $whiteSpace="nowrap">
                          Add to list
                        </Caption>
                      </S.ItemCta>
                    </S.ResultItem>
                  </DivAsButton>
                ))}
              </S.ResultInner>
            </S.Result>
          </When>
        </S.SearchInput>
      </Form>
    </S.CompaniesSearch>
  )
}
